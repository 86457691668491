<template>
  <page-content :show-search="true">
    <template #search>
      <table-search
        ref="search"
        :fields="search_fields"
        :default="search_default"
        @runSearch="runSearch"
        @changeField="searchChangeField"
      />
    </template>

    <template #default>
      <table-header
        ref="header"
        :title="title"
        :button-actions="header_actions"
        @runSearch="runSearch"
        :search-tips="$t('kiosk.search_tips')"
      />

      <table-content
        :per-page="page_condition.pageSize"
        :per-page-dropdown-enabled="true"
        @perPageChange="perPageChange"
        :columns="columns"
        :rows="rows"
        :total-rows="total_rows"
        :current-page="current_page"
        :action-options="table_actions"
        @runTableAction="runTableAction"
        @sortChange="sortChange"
        @pageChange="pageChange"
        :permission="view_permission"
      />
    </template>

  </page-content>
</template>

<script>
import common from '@/common'
import TableContent from '@/components/TableContent'
import TableHeader from '@/components/TableHeader'
import TableSearch from '@/components/TableSearch'
import PageContent from '@/components/PageContent'
import kioskUtils from '@/libs/kiosk-utils'

export default {
  components: {
    PageContent,
    TableSearch,
    TableHeader,
    TableContent,
  },
  name: 'kioskManageVersion',
  data() {
    return {
      title: common.getMenuName('kioskManageVersion'),
      view_permission: common.checkPermission('Kiosk_Menu_Version'),
      // view_permission: true,
      rows: [],
      total_rows: 0,
      sort_condition: {},
      current_page: 1,
      page_condition: {
        pageIndex: 0,
        pageSize: common.getTablePerPage(),
      },
      status_map: [],
      header_actions: [
        {
          text: 'kiosk.upgrade',
          variant: 'primary',
          fun: this.upgradeKiosk,
          permission: 'Kiosk_Menu_Version',
        },
      ],
      columns: [
        {
          label: 'common.row_index',
          field: 'row_index',
          sortable: false,
          width: '50px',
        },
        {
          label: 'kiosk.device_token',
          field: 'kioskToken',
          // width: '200px',
        },
        {
          label: 'kiosk.device_name',
          field: 'kioskName',
          // width: '210px',
        },
        {
          label: 'kiosk.device_platform',
          field: 'platform',
          // width: '80px',
        },
        {
          label: 'kiosk.package_current_version',
          field: 'currentVersion',
          // width: '180px',
        },
        {
          label: 'kiosk.package_target_version',
          field: 'targetVersion',
          // width: '180px',
        },
        {
          label: 'common.action',
          field: 'action',
          width: '180px',
          sortable: false,
        },
      ],
      table_actions: [
        {
          text: 'kiosk.upgrade',
          variant: 'primary',
          fun: 'upgradeKiosk',
          permission: 'Kiosk_Menu_Version',
        },
        {
          text: 'kiosk.upgrade_cancel',
          variant: 'danger',
          fun: 'revokeUpgradeKiosk',
          permission: 'Kiosk_Menu_Version',
          check_disable: (vm, props) => {
            return props?.row?.targetVersion === null
          },
        },
      ],
      search_fields: [
        {
          field: 'companyID',
          label: 'common.company',
          type: 'select',
          options: [],
          // clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'facilityID',
          label: 'common.facility',
          type: 'select',
          options: [],
          clearable: false,
          show: common.isAdmin(),
        },
        {
          field: 'platform',
          label: 'kiosk.device_platform',
          type: 'select',
          options: [],
          show: common.isAdmin(),
          clearable: true,
        },
      ],
      search_default: {
        companyID: null,
        facilityID: null,
        floorID: common.getCustomConfigByKey('floorID'),
        roomID: common.getCustomConfigByKey('roomID'),
      },
    }
  },
  mounted() {
    if (common.isAdmin()) {
      common.getSearchCompanyOptions('companyID', this)
      common.getSearchFacilityOptions('facilityID', common.getAdminCompanyId(), this)
      kioskUtils.getKioskEnvOptions().then(({ envOptions, platformOptions }) => {
        platformOptions.forEach(option => this.search_fields.find(field => field.field === 'platform').options.push(option))
      })
      this.getList()
    }
  },

  methods: {
    getList: async function () {
      if (!this.view_permission) return

      const api = '/kioskupdate/GetKioskList'
      const search = this.getSearchCondition()
      const url = common.getTableUrl(api, search, this.sort_condition, this.page_condition)
      const { data } = await common.apiGetData(url)

      // Assign value to current component
      this.total_rows = data.total
      this.rows = data.kiosks
    },
    perPageChange(size) {
      this.page_condition.pageIndex = 0
      this.page_condition.pageSize = size
      common.setTablePerPage(size)
      this.getList()
    },

    runSearch: function () {
      this.current_page = 1
      this.page_condition.pageIndex = 0
      this.getList()
    },
    async searchChangeField(field, value) {
      await common.searchChangeField({
        fieldName: field,
        newValue: value,
        currentVm: this,
        searchVm: this.$refs.search,
        isSyncGlobally: false,
      })
    },

    upgradeKiosk(data) {
      if (data && data.kioskToken && data.platform) {
        this.$router.push({
          name: 'kioskManageVersion_upgrade_one',
          params: {
            token: common.encrypt(data.kioskToken),
            platform: common.encrypt(data.platform),
          },
        })
      } else {
        this.$router.push({ name: 'kioskManageVersion_upgrade' })
      }
    },
    revokeUpgradeKiosk(data) {
      common.showConfirm({
        title: `撤銷升級?`,
        text: `${data.kioskToken} | ${data.kioskName} | ${data.platform}`,
        confirm_fun: async () => {
          await this.$requestWehealth({
            url: '/kioskupdate/CancelUpgrade',
            method: 'post',
            params: {
              kioskToken: data.kioskToken,
            },
          })
          common.showToast({ title: `撤銷升級成功` })
          this.getList()
        },
      })
    },

    runTableAction: function (action, data) {
      this[action](data)
    },
    sortChange: function (value) {
      this.sort_condition = value
      this.getList()
    },
    getSearchCondition: function () {
      this.$refs.search.buildSearchCondition()

      let where = {}
      where['searchContent'] = this.$refs.header.searchContent
      where = Object.assign(where, this.$refs.search.search_condition)

      return where
    },
    pageChange: function (value) {
      this.current_page = value
      this.page_condition.pageIndex = value - 1
      this.getList()
    },
    changeStatus: function (value) {
      for (const i in this.status_map) {
        if (this.status_map[i].statusCode === value) {
          return this.status_map[i].statusDesc
        }
      }
    },
    changeTimeFormat(time) {
      if (time === null) {
        return ''
      } else {
        return this.$moment(time)
          .format('YYYY-MM-DD HH:mm:ss')
      }
    },
  },
}
</script>

<style lang="scss">

</style>
